import { TimeUnit, TimeUnitParams } from "vega-lite/build/src/timeunit.js";

import { ExploreCustomTimeUnit } from "../explore/exploreCustomTimeUnit.js";

import { ChartTimeUnit, ChartTimeUnitConfig, ChartWeekStart } from "./types.js";

export const getVegaLiteTimeUnit = (
  timeUnit: ChartTimeUnit | undefined,
  timeUnitConfig: ChartTimeUnitConfig | undefined,
): TimeUnit | TimeUnitParams | undefined => {
  if (ExploreCustomTimeUnit.guard(timeUnit)) {
    return undefined;
  }
  if (isMondayStartWeek(timeUnit, timeUnitConfig)) {
    return {
      unit: "yearmonthdate",
      step: 7,
      binned: true,
    };
  }
  return timeUnit;
};

export const isMondayStartWeek = (
  timeUnit: ChartTimeUnit | undefined,
  timeUnitConfig: ChartTimeUnitConfig | undefined,
): boolean => {
  return timeUnit === "yearweek" && timeUnitConfig?.weekStart === "monday";
};

export const getResolvedWeekStart = (
  timeUnitConfig: ChartTimeUnitConfig | undefined,
): ChartWeekStart => {
  // legacy charts will not have a timeUnitConfig and should be assumed to use sunday weeks
  // new charts will have a timeUnitConfig set
  return timeUnitConfig?.weekStart ?? LEGACY_DEFAULT_WEEK_START;
};

export const WEEK_OVERRIDE_FORMAT = "%b %d, %Y";
export const NEW_DEFAULT_WEEK_START: ChartWeekStart = "monday";
export const LEGACY_DEFAULT_WEEK_START: ChartWeekStart = "sunday";
