import { unescapeVegaString } from "../vega-chart-cell/vegaEscape.js";
import { verbalTitleFormatter } from "../vega-chart-cell/vegaUtils.js";

import {
  ChartAggregate,
  ChartSqlColumnMappings,
  ChartTimeUnit,
} from "./types.js";

export function chartEncodingTitle({
  aggregate,
  columnMappings,
  dataFrameColumn,
  timeUnit,
}: {
  dataFrameColumn?: string;
  aggregate?: ChartAggregate;
  timeUnit?: ChartTimeUnit;
  // We create new column names as part of pre-aggregating the data as a pre-step
  // to ChartSpec -> VegaLite spec translation. columnMappings is a mapping of
  // newly generated column names to the original column names.
  // As the sql pre-step only happens as part of chart spec translation, this is
  // only relevant in the Kernel where the translation takes place.
  columnMappings?: ChartSqlColumnMappings;
}): string | undefined {
  if (dataFrameColumn == null) {
    return undefined;
  }

  const field =
    columnMappings?.[dataFrameColumn] ??
    // we escape special characters in dataFrameColumn values so that
    // vega doesn't crash, but of course then vega messes up the generated
    // axis label -- instead of "this.column.has.periods" it renders
    // "this\.column\.has\.periods". To get around this issue, perform
    // a check on the column name to see if it's likely to have been
    // escaped and if so, manually generate a default title with unescaped
    // dataframe column value.
    // However, for those in the columnMappings, the column name was never
    // escaped in the first place so we don't need to escape those.
    unescapeVegaString(dataFrameColumn);

  return verbalTitleFormatter({
    field,
    aggregate,
    timeUnit,
  });
}
